import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { fromText as getOwnerTypeFromText } from "@/types/attachmentTypes/AttachmentOwnerType";
import SpinnerMixin from "@/mixins/spinner";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
import SearchToolbar from "@/components/Templates/Attachment/SearchToolbar.vue";
import AttachmentAvailabilityWrapper from "@/components/Attachments/AttachmentAvailabilityWrapper.vue";
Vue.use(GridPlugin);
export default Vue.extend({
    components: { AttachmentAvailabilityWrapper, "search-toolbar": SearchToolbar },
    mixins: [TitleMixin, SpinnerMixin, GridMixin],
    data() {
        return {
            title: this.$t("attachments.attachments"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + `${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/grid-data`,
                removeUrl: process.env.VUE_APP_API_BASE_URL + `${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/grid-delete`,
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [
                    { field: "AttachmentTypeName", direction: "Ascending" },
                    { field: "FileName", direction: "Ascending" }
                ]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            searchOptions: {
                fields: ["Name", "FileName"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Download",
                    title: this.$t("attachments.download"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-download e-icons" }
                },
                {
                    type: "Log",
                    title: this.$t("attachments.logs.logs"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-justify e-icons" }
                },
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ]
        };
    },
    provide: {
        grid: [CommandColumn, Page, Filter, Sort, Toolbar, Search, Edit]
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push(`/${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/create`);
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn && args.commandColumn.type == "Log") {
                this.$router.push(`/${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/${args.rowData.id}/logs`);
                return;
            }
            if (args.commandColumn && args.commandColumn.type == "Download") {
                this.downloadAttachment(args.rowData.id, args.rowData.fileName);
                return;
            }
            if (args.commandColumn && args.commandColumn.type == "Custom_Edit") {
                this.$router.push(`/${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/${args.rowData.id}/edit`);
                return;
            }
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("attachments.delete.toastSuccessTitle"), this.$t("attachments.delete.toastSuccessContent", {
                    name: `${args.data[0].name}`
                }));
            }
        },
        getAttachmentFile(id) {
            switch (this.$route.params.ownerType) {
                case "customers":
                    return this.$store.dispatch("customer/downloadAttachment", {
                        id: id,
                        customerId: this.$route.params.ownerId,
                        attachment: this.attachment
                    });
                case "employees":
                    return this.$store.dispatch("employee/downloadAttachment", {
                        id: id,
                        employeeId: this.$route.params.ownerId,
                        attachment: this.attachment
                    });
                default:
                    throw new Error(`The owner type '${this.$route.params.ownerType}' is not managed.`);
            }
        },
        async downloadAttachment(id, fileName) {
            this.showSpinner();
            try {
                const file = await this.getAttachmentFile(id);
                const href = URL.createObjectURL(file);
                const downloadLink = document.createElement("a");
                downloadLink.href = href;
                downloadLink.download = fileName;
                downloadLink.click();
                URL.revokeObjectURL(href);
            }
            catch (errors) {
                this.$toast.showWarningToast(this.$t("shared.toastFailureTitle"), errors[0].message);
            }
            finally {
                this.hideSpinner();
            }
        },
        getOwnerType() {
            return getOwnerTypeFromText(this.$route.params.ownerType);
        },
        search(args) {
            const grid = this.$refs["attachments-grid"];
            const gridInstance = grid.ej2Instances;
            // Build query
            const query = new Query();
            if (args.attachmentTypeId != null)
                query.addParams("attachmentTypeId", args.attachmentTypeId.toString());
            // Set the query and refresh the grid
            gridInstance.query = query;
            grid.refresh();
        }
    },
    async mounted() {
        try {
            if (this.$route.params.ownerType === "customers") {
                const customer = await this.$store.dispatch("customer/get", {
                    id: this.$route.params.ownerId
                });
                this.setTitle(`${this.$t("attachments.attachments")} di ${customer.name} ${customer.surname}`);
            }
            else if (this.$route.params.ownerType === "employees") {
                const employee = await this.$store.dispatch("employee/get", {
                    id: this.$route.params.ownerId
                });
                this.setTitle(`${this.$t("attachments.attachments")} di ${employee.name} ${employee.surname}`);
            }
            else {
                throw new Error(`The owner type '${this.$route.params.ownerType}' is not managed.`);
            }
        }
        catch {
            /* Empty */
        }
    }
});
