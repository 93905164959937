import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            isAvailable: undefined
        };
    },
    async beforeMount() {
        this.showSpinner();
        try {
            this.isAvailable = await this.$store.dispatch("attachment/isAvailable");
        }
        catch (errors) {
            this.isAvailable = false;
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
        finally {
            this.hideSpinner();
        }
    }
});
