import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    props: {
        ownerType: {
            type: Number,
            required: true
        },
    },
    mixins: [SpinnerMixin],
    data() {
        return {
            attachmentTypes: [],
            attachmentTypeId: null
        };
    },
    methods: {
        search() {
            this.$emit("search", {
                attachmentTypeId: this.attachmentTypeId
            });
        }
    },
    async mounted() {
        //this.showSpinner();
        try {
            this.attachmentTypes = await this.$store.dispatch("attachmentType/getAll", {
                ownerType: this.ownerType
            }).then((attachmentTypes) => attachmentTypes.filter(attachmentType => attachmentType.deletedAt == null));
            this.attachmentTypes.unshift({
                id: null,
                name: 'Tutti'
            });
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
        finally {
            //this.hideSpinner()
        }
    }
});
