export var AttachmentOwnerType;
(function (AttachmentOwnerType) {
    AttachmentOwnerType[AttachmentOwnerType["Customer"] = 1] = "Customer";
    AttachmentOwnerType[AttachmentOwnerType["Employee"] = 2] = "Employee";
})(AttachmentOwnerType || (AttachmentOwnerType = {}));
export function fromText(ownerType) {
    if (ownerType.match("^customers?$"))
        return AttachmentOwnerType.Customer;
    if (ownerType.match("^employees?$"))
        return AttachmentOwnerType.Employee;
    throw new Error(`The owner type ${ownerType} is not managed.`);
}
